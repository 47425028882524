<template>
  <range :start="startDate" :end="endDate" @picker="handleChange" />
</template>

<script>
import Range from './RangePicker'
export default {
  components: {
    Range
  },
  data () {
    return {
      startDate: '',
      endDate: ''
    }
  },
  methods: {
    handleChange (value) {
      console.log(value, "From Change");
    }
  },
}
</script>
